// extracted by mini-css-extract-plugin
export var defaultFont = "base-module--default-font--JnMT6";
export var fontWeight500 = "base-module--font-weight-500--ad-RC";
export var codeFont = "base-module--code-font--zTGXK";
export var textGreen = "base-module--text-green--TizRp";
export var clearButtonStyles = "base-module--clear-button-styles--pAaSt";
export var new_section = "base-module--new_section--IthPH";
export var endpoint = "base-module--endpoint--lh4rs";
export var params_list = "base-module--params_list--xKbbt";
export var params_list_inner = "base-module--params_list_inner--+gm6F";
export var param_name = "base-module--param_name--S8Lm5";
export var param_default_value = "base-module--param_default_value--u1JCI";
export var param_example = "base-module--param_example--duyTd";
export var param_description = "base-module--param_description--7jfF3";