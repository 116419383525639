import React from "react"
import Base, { ParameterGroup, createCodeFromParams } from "./_base"

const params = [
  {
    name: "id",
    type: "uuid",
    description: "The UUID of the location.",
    example: process.env.GATSBY_COMMON_UUID,
    required: true,
    defaultValue: "NULL",
    exampleValue: `"${process.env.GATSBY_COMMON_UUID}"`,
  },
]

const ViewLocation = () => {
  return (
    <Base
      title={"View Location"}
      id={"location_view"}
      endpoint={"/location/view"}
      description={"Returns the Location object for a given uuid."}
      codeSnippets={[
        {
          type: "body",
          code: createCodeFromParams(params),
        },
        {
          type: "response",
          code: response,
        },
      ]}
    >
      <ParameterGroup title={"Request body parameters"} params={params} />
    </Base>
  )
}

export default ViewLocation

const response = `{
    "message": "Success",
    "location": {
        "location_id": "dbd69df5-cc6b-48ba-b879-f648e6cf1dfb",
        "office_term_commitment": "Month to Month",
        "dedicated_desks_term_commitment": "Month to Month",
        "office_access_copy": "",
        "location": {
            "lat": 43.51245979999999,
            "lng": -79.6782796,
            "address": "Winston Park Dr, Oakville, ON L6H, Canada",
            "heading": 0,
            "camera": {
                "lat": 43.51245979999999,
                "lng": -79.6782796
            },
            "short_address": "Winston Park Dr",
            "city": "Oakville",
            "place_id": "EilXaW5zdG9uIFBhcmsgRHIsIE9ha3ZpbGxlLCBPTiBMNkgsIENhbmFkYSIuKiwKFAoSCY3OZTMKQyuIEY1TMEnQGP4REhQKEgl5mXKPi1sriBHQGyPHKHsDBQ"
        },
        "cover_image": "https://images.worksimply.ca/local/space-providers/fa89d6e6-c7ab-4ebf-81e9-050a6df7825c.jpg",
        "logo_url": null,
        "space_title": "Pay for what you need, when you need it.",
        "space_description": "Pay for what you need, when you need it. Our bright and inspiring private offices come fully equipped so you can focus on your business.",
        "high_speed_internet": true,
        "additional_includes": [
            "Option 1",
            "Option 2"
        ],
        "user_image_url": "https://images.worksimply.ca/local/space-providers/f4f28771-3b05-45c7-a08a-b0ff9f1f84d9.jpg",
        "contact_name": "Johm Smith",
        "contact_position": "Community Manager",
        "contact_email": "hello@email.com",
        "space_name": "Worksimply Test Location",
        "space_type": "Coworking Space",
        "on_demand_emails": "hello@email.com",
        "city": "Toronto",
        "on_demand_instructions": "The first line of test instructions\\nAnother line of test instructions",
        "price_per_desk": 25,
        "timezone": "America/Toronto",
        "currency": "cad",
        "space_operator_id": "d241965e-8046-4cf5-a4c4-a1c462b066d0",
        "floors": [
            {
                "floor_id": "59540e96-b496-4deb-9987-e30e67770402",
                "floor_name": "Floor 1",
                "map_svg_url": "https://images.worksimply.ca/local/space-providers/dcca3da7-01c1-4a14-b218-42e67f5cb494.svg",
                "map_background_url": "https://images.worksimply.ca/local/space-providers/bfddb5d6-991e-4044-a4e3-aec7956e1e3d.jpg",
                "offices": [
                    {
                        "office_id": "c92fce94-33a8-4ae9-8e34-c089b0d3b075",
                        "svg_identifier": "x:678.5|y:67.5|width:212|height:202",
                        "price_per_month": 3000,
                        "capacity": 3,
                        "square_footage": 0,
                        "space_available": true,
                        "space_available_from": null,
                        "floor_id": "59540e96-b496-4deb-9987-e30e67770402",
                        "price_per_day": 300,
                        "price_per_hour": 30,
                        "label": "",
                    },
                    {
                        "office_id": "f37fe255-c020-4f70-88e6-84659d76280e",
                        "svg_identifier": "x:890.5|y:67.5|width:206|height:202",
                        "price_per_month": 4000,
                        "capacity": 4,
                        "square_footage": 0,
                        "space_available": true,
                        "space_available_from": null,
                        "floor_id": "59540e96-b496-4deb-9987-e30e67770402",
                        "price_per_day": 400,
                        "price_per_hour": 40,
                        "label": "",
                    }
                ]
            }
        ],
        "amenities": [
            {
                "id": "wifi",
                "value": null,
                "text": ""
            },
            {
                "id": "furnished",
                "value": true,
                "text": "Fully furnished"
            },
            {
                "id": "pet_friendly",
                "value": true,
                "text": "Pet friendly"
            },
            {
                "id": "full_kitchen_access",
                "value": true,
                "text": "Full kitchen access"
            },
            {
                "id": "coffee",
                "value": true,
                "text": "Coffee & Tea"
            },
            {
                "id": "printing",
                "value": true,
                "text": "Printing"
            },
            {
                "id": "common_area",
                "value": true,
                "text": "Common areas"
            },
            {
                "id": "mail_box",
                "value": true,
                "text": "Mail box"
            },
            {
                "id": "high_speed_internet",
                "value": true,
                "text": "High Speed Internet"
            },
            {
                "id": "breakout_rooms",
                "value": 0,
                "text": ""
            },
            {
                "id": "meeting_rooms",
                "value": 2,
                "text": "Access to 2 meeting rooms"
            },
            {
                "id": "phone_booths",
                "value": 3,
                "text": "Access to 3 phone booths"
            },
            {
                "id": "ergonomic_chairs",
                "value": false,
                "text": ""
            }
        ],
        "common_images": [
            {
                "common_image_id": "fb73abc9-6ea9-4ab1-b0e7-2a51d15447d4",
                "url": "https://images.worksimply.ca/local/space-providers/8e008597-8c97-46fe-a2f7-c8ba1c20de1f.jpg"
            },
            {
                "common_image_id": "e263816a-d405-4800-9243-9e77149f985f",
                "url": "https://images.worksimply.ca/local/space-providers/2f30bae9-2e2b-4de5-9453-af6653bfe33b.jpg"
            }
        ]
    }
}`
