import React from "react"
import Base, { ParameterGroup, createCodeFromParams } from "./_base"

const params = [
  {
    name: "name",
    type: "string",
    description: "The name of the Enterprise",
    required: true,
    exampleValue: "Enterprise A",
  },
  {
    name: "external_id",
    type: "string",
    description: "The unique ID of the enterprise that are used in your system",
    required: true,
    exampleValue: "7160144241",
  },
  {
    name: "stripe_customer_id",
    type: "string",
    description: "The name of the Enterprise",
    required: true,
    exampleValue: "cus_Je6HsyT4Db6wEc",
  },
  {
    name: "stripe_payment_method_id",
    type: "string",
    description: "The name of the Enterprise",
    required: true,
    exampleValue: "pm_1J0o5OJo8ARofgK0nh5IxWo2",
  },
]

const CreateEnterprise = () => {
  return (
    <Base
      title={"Create Enterprises"}
      id={"enterprises_create"}
      endpoint={"/enterprises/create"}
      description={"This endpoint creates an Enterprise"}
      codeSnippets={[
        {
          type: "body",
          code: createCodeFromParams(params),
        },
        {
          type: "response",
          code: response,
        },
      ]}
    >
      <ParameterGroup title={"Request body parameters"} params={params} />
    </Base>
  )
}

export default CreateEnterprise

const response = `{
    "message": "Success",
    "enterprise": {
        "name": "Enterprise A",
        "external_id": "7160144241",
        "stripe_customer_id": "cus_Je6HsyT4Db6wEc",
        "stripe_payment_method_id": "pm_1J0o5OJo8ARofgK0nh5IxWo2",
        "enterprise_id": "fffa1cb3-6a19-4078-a6c5-9f1c79acbba6"
    }
}`
