import React from "react"
import Base, { ParameterGroup, createCodeFromParams } from "./_base"

const params = [
  {
    name: "location_id",
    type: "uuid",
    description: "The uuid of the selected location.",
    example: "",
    required: true,
    exampleValue: `"${process.env.GATSBY_COMMON_UUID}"`,
  },
  {
    name: "isDaily",
    type: "bool",
    description:
      "Boolean value. True will retrieve the list of locations for daily reservation. False will retrieve the list of locations for hourly reservation.",
    example: "",
    exampleValue: "false",
  },
  {
    name: "team_size",
    type: "integer",
    description:
      "Team size of the user. Usually this is an integer from 1 - 10",
    example: "",
    exampleValue: "2",
  },
  {
    name: "start_date",
    type: "date",
    description: "The start date selected by the user, in YYYY-MM-DD format.",
    example: "2021-05-14",
    exampleValue: "2021-05-14",
    required: true,
    quotes: true,
  },
  {
    name: "end_date",
    type: "date",
    description: "The end date selected by the user, in YYYY-MM-DD format.",
    example: "2021-05-16",
    exampleValue: "2021-05-16",
    required: true,
    quotes: true,
  },
  {
    name: "start_time",
    type: "string",
    description:
      "The start time selected by the user. This parameter is required if the type is 'Hourly'",
    example: "01:00 PM",
    defaultValue: "NULL",
    exampleValue: "01:00 PM",
  },
  {
    name: "end_time",
    type: "string",
    description:
      "The end time selected by the user. This parameter is required if the type is 'Hourly'",
    example: "04:00 PM",
    defaultValue: "NULL",
    exampleValue: "04:00 PM",
  },
  {
    name: "option_cap",
    type: "string",
    description: "Some description",
    example: "2",
    defaultValue: "2",
    exampleValue: "2",
  },
]

const FilterOnDemandOptions = () => {
  return (
    <Base
      title={"Filter On-demand Offices"}
      id={"filter_on_demand_offices"}
      endpoint={"/location/filter"}
      description={
        "This endpoint returns a list of available on-demand offices for a single location based on given parameters."
      }
      codeSnippets={[
        {
          type: "body",
          code: createCodeFromParams(params),
        },
        {
          type: "response",
          code: response,
        },
      ]}
    >
      <ParameterGroup title={"Request body parameters"} params={params} />
    </Base>
  )
}

export default FilterOnDemandOptions

const response = `{
    "message": "Success",
    "officesWithFloors": [
        {
            "floor_id": "59540e96-b496-4deb-9987-e30e67770402",
            "plan_svg": "https://images.worksimply.ca/local/space-providers/dcca3da7-01c1-4a14-b218-42e67f5cb494.svg",
            "plan_bg": "https://images.worksimply.ca/local/space-providers/bfddb5d6-991e-4044-a4e3-aec7956e1e3d.jpg",
            "offices": [
                {
                    "id": "c92fce94-33a8-4ae9-8e34-c089b0d3b075",
                    "svg_identifier": "x:678.5|y:67.5|width:212|height:202",
                    "price": 3000,
                    "max_capacity": 3,
                    "square_footage": 0,
                    "floor_id": "59540e96-b496-4deb-9987-e30e67770402",
                    "price_per_day": 300,
                    "price_per_hour": 30,
                    "label": "",
                    "asset_url": null,
                    "office_id": "c92fce94-33a8-4ae9-8e34-c089b0d3b075",
                    "capacity": 3,
                    "plan_svg": "https://images.worksimply.ca/local/space-providers/dcca3da7-01c1-4a14-b218-42e67f5cb494.svg",
                    "plan_bg": "https://images.worksimply.ca/local/space-providers/bfddb5d6-991e-4044-a4e3-aec7956e1e3d.jpg"
                },
                {
                    "id": "f37fe255-c020-4f70-88e6-84659d76280e",
                    "svg_identifier": "x:890.5|y:67.5|width:206|height:202",
                    "price": 4000,
                    "max_capacity": 4,
                    "square_footage": 0,
                    "floor_id": "59540e96-b496-4deb-9987-e30e67770402",
                    "price_per_day": 400,
                    "price_per_hour": 40,
                    "label": "",
                    "asset_url": null,
                    "office_id": "f37fe255-c020-4f70-88e6-84659d76280e",
                    "capacity": 4,
                    "plan_svg": "https://images.worksimply.ca/local/space-providers/dcca3da7-01c1-4a14-b218-42e67f5cb494.svg",
                    "plan_bg": "https://images.worksimply.ca/local/space-providers/bfddb5d6-991e-4044-a4e3-aec7956e1e3d.jpg"
                }
            ]
        }
    ]
}`
