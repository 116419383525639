import React from "react"
import Layout from "../../Layout"
import Code from "../../components/Code"
import SearchDayPasses from "./Endpoints/_SearchDayPasses"
import SearchOnDemandOffices from "./Endpoints/_SearchOnDemandOffices"
import ViewLocation from "./Endpoints/_ViewLocation"
import FilterOnDemandOptions from "./Endpoints/_FilterOnDemandOptions"
import ReserveDayPasses from "./Endpoints/_ReserveDayPasses"
import ReserveOnDemandOffices from "./Endpoints/_ReserveOnDemandOffices"
import CancelDesks from "./Endpoints/_CancelDesk"
import CancelOnDemandOffices from "./Endpoints/_CancelOnDemandOffices"
import ConfirmDesk from "./Endpoints/_ConfirmDesk"
import ConfirmOnDemandOffice from "./Endpoints/_ConfirmOnDemandOffice"
import CreateEnterprise from "./Endpoints/_EnterprisesCreate"
import UpdateEnterprise from "./Endpoints/_EnterprisesUpdate"
import ListEnterprise from "./Endpoints/_EnterprisesList"
import * as s from "./index.module.less"

const ApiReferencePage = () => {
  return (
    <Layout
      title={"Worksimply API"}
      description={
        "Welcome to the Worksimply API. Use this API to access endpoints for real-time data from the Space Operators."
      }
    >
      <div className="row">
        <div className="col-lg-8">
          <p>
            Welcome to the Worksimply API. Use this API to access endpoints for
            real-time data from the Space Operators.
          </p>
        </div>
      </div>

      <NewSection title={"Server"}>
        <Code
          title={"URL"}
          lang={"bash"}
        >{`${process.env.GATSBY_API_DOMAIN}`}</Code>
      </NewSection>

      <NewSection title={"Enterprises"}>
        <p>
          Enterprises endpoints will create, update and list the enterprises.
        </p>
      </NewSection>

      <CreateEnterprise />
      <UpdateEnterprise />
      <ListEnterprise />

      <NewSection title={"Search"}>
        <p>
          Search endpoints will return the list of matching locations for a
          given search criteria.
        </p>
      </NewSection>

      <SearchDayPasses />
      <SearchOnDemandOffices />

      <NewSection title={"Location Profile"}>
        <p>Endpoints for the actions inside the location profile.</p>
      </NewSection>

      <ViewLocation />
      <FilterOnDemandOptions />

      <NewSection title={"Reservations"}>
        <p>Endpoints for reservations.</p>
      </NewSection>

      <ReserveDayPasses />
      <ReserveOnDemandOffices />

      <NewSection>
        <p>Endpoints for confirming the tentative reservations</p>
      </NewSection>

      <ConfirmDesk />
      <ConfirmOnDemandOffice />

      <NewSection title={"Cancelling Reservations"}>
        <p>Endpoints for cancelling the reservations.</p>
      </NewSection>

      <CancelDesks />
      <CancelOnDemandOffices />
    </Layout>
  )
}

export default ApiReferencePage

const NewSection = ({ title = "", children }) => {
  return (
    <div className={s.new_section}>
      <div className="row">
        <div className="col-xl-8">
          <h2>{title}</h2>
          {children}
        </div>
      </div>
    </div>
  )
}
