import React from "react"
import Base, { ParameterGroup, createCodeFromParams } from "./_base"

const params = [
  {
    name: "reservation_id",
    type: "uuid",
    description: "The uuid of the reservation",
    required: true,
    exampleValue: `"3b9bfff7-b692-424f-8ab3-cc6627fb1c40"`,
  },
  {
    name: "note",
    type: "text",
    required: true,
    description:
      "The reason for the cancellation. Should be less than 200 characters.",
    example: "",
    quotes: true,
    exampleValue: "Reason for the cancellation",
  },
]

const CancelDesks = () => {
  return (
    <Base
      title={"Cancel Desk Reservation"}
      id={"cancel_reservation_desks"}
      endpoint={"/cancel-reservation/desk"}
      description={"This endpoint cancels a reservation made for desks"}
      codeSnippets={[
        {
          type: "body",
          code: createCodeFromParams(params),
        },
        {
          type: "response",
          code: response,
        },
      ]}
    >
      <ParameterGroup title={"Request body parameters"} params={params} />
    </Base>
  )
}

export default CancelDesks

const response = `{
    "message": "Success",
    "reservation": {
        "reservation_id": "3b9bfff7-b692-424f-8ab3-cc6627fb1c40",
        "cancellation_id": "1260241e-71b5-4e1d-9409-2e073f533f6f",
        "note": "Reason for the cancellation",
    }
}`
