import React from "react"
import Base, { ParameterGroup, createCodeFromParams } from "./_base"

const params = [
  {
    name: "space_operator_id",
    type: "uuid",
    description:
      "The uuid of the Space Operator. If specified, this endpoint will retrieve the locations that belongs to the specified SO.",
    example: "",
    exampleValue: `"${process.env.GATSBY_COMMON_UUID}"`,
  },
  {
    name: "option_count",
    type: "integer",
    description:
      "This limits the number of matched offices returned per location. Defaults to two.",
    exampleValue: 2,
    defaultValue: 2,
  },
  {
    name: "params",
    type: "object",
    description: "The start date selected by the user",
    example: "",
    exampleValue: "",
    required: true,
    items: [
      {
        name: "team_size",
        type: "integer",
        description: "Team size to look the available offices for.",
        required: true,
        defaultValue: 2,
        exampleValue: "2",
      },
      {
        name: "location",
        type: "string",
        description: "The city of the user.",
        example: "",
        defaultValue: "Toronto",
        exampleValue: "Toronto",
        required: true,
      },
      {
        name: "start_date",
        type: "date",
        description:
          "The start date selected by the user, in YYYY-MM-DD format.",
        example: "2021-05-14",
        exampleValue: "2021-05-14",
        required: true,
        quotes: true,
      },
      {
        name: "end_date",
        type: "date",
        description: "The end date selected by the user, in YYYY-MM-DD format.",
        example: "2021-05-16",
        exampleValue: "2021-05-16",
        required: true,
        quotes: true,
      },
      {
        name: "on_demand_type",
        type: "string",
        description:
          "The type of the on-demand search. Should be one of 'Hourly' or 'Daily'",
        example: "Daily",
        defaultValue: "Daily",
        exampleValue: "Hourly",
      },
      {
        name: "start_time",
        type: "string",
        description:
          "The start time selected by the user. This parameter is required if the type is 'Hourly'",
        example: "01:00 PM",
        defaultValue: "NULL",
        exampleValue: "01:00 PM",
      },
      {
        name: "end_time",
        type: "string",
        description:
          "The end time selected by the user. This parameter is required if the type is 'Hourly'",
        example: "04:00 PM",
        defaultValue: "NULL",
        exampleValue: "04:00 PM",
      },
    ],
  },
]

const SearchOnDemandOffices = () => {
  return (
    <Base
      title={"Search On-demand Offices"}
      id={"search_on_demand_offices"}
      endpoint={"/search/on-demand-offices"}
      description={
        "This endpoint retrieves a list of Location objects that offers on-demand offices for the specified search criteria."
      }
      codeSnippets={[
        {
          type: "body",
          code: createCodeFromParams(params),
        },
        {
          type: "response",
          code: response,
        },
      ]}
    >
      <ParameterGroup title={"Request body parameters"} params={params} />
    </Base>
  )
}

export default SearchOnDemandOffices

const response = `{
    "message": "Success",
    "user": {
        "team_size": "2",
        "location": "Toronto",
        "space_operator_id": null,
        "daily": false,
        "start_date": "2021-05-14",
        "end_date": "2021-05-16",
        "start_time": "01:00 PM",
        "end_time": "04:00 PM"
    },
    "locations": [
        {
            "location_id": "c92fce94-33a8-4ae9-8e34-c089b0d3b075",
            "location": {
                "lat": 43.51245979999999,
                "lng": -79.6782796,
                "address": "Winston Park Dr, Oakville, ON L6H, Canada",
                "heading": 0,
                "camera": {
                    "lat": 43.51245979999999,
                    "lng": -79.6782796
                },
                "short_address": "Winston Park Dr",
                "city": "Oakville",
                "place_id": "EilXaW5zdG9uIFBhcmsgRHIsIE9ha3ZpbGxlLCBPTiBMNkgsIENhbmFkYSIuKiwKFAoSCY3OZTMKQyuIEY1TMEnQGP4REhQKEgl5mXKPi1sriBHQGyPHKHsDBQ"
            },
            "space_operator_id": "d241965e-8046-4cf5-a4c4-a1c462b066d0",
            "name": "Worksimply Office No.2",
            "title": "Pay for what you need, when you need it. ",
            "cover_image": "https://images.worksimply.ca/local/space-providers/fa89d6e6-c7ab-4ebf-81e9-050a6df7825c.jpg",
            "offices": [
                {
                    "office_id": "c92fce94-33a8-4ae9-8e34-c089b0d3b075",
                    "svg_identifier": "x:678.5|y:67.5|width:212|height:202",
                    "price_per_month": 3000,
                    "price_per_day": 300,
                    "price_per_hour": 30,
                    "plan_svg": "https://images.worksimply.ca/local/space-providers/dcca3da7-01c1-4a14-b218-42e67f5cb494.svg",
                    "plan_bg": "https://images.worksimply.ca/local/space-providers/bfddb5d6-991e-4044-a4e3-aec7956e1e3d.jpg",
                    "capacity": 3,
                    "square_footage": 0
                },
                {
                    "office_id": "f2d10a08-5e5b-409e-af1f-c7c5e9a8ace4",
                    "svg_identifier": "x:1310.5|y:67.5|width:211|height:202",
                    "price_per_month": 3500,
                    "price_per_day": 350,
                    "price_per_hour": 35,
                    "plan_svg": "https://images.worksimply.ca/local/space-providers/dcca3da7-01c1-4a14-b218-42e67f5cb494.svg",
                    "plan_bg": "https://images.worksimply.ca/local/space-providers/bfddb5d6-991e-4044-a4e3-aec7956e1e3d.jpg",
                    "capacity": 6,
                    "square_footage": 0
                }
            ]
        }
    ]
}`
