import React from "react"
import Base, { ParameterGroup, createCodeFromParams } from "./_base"

const params = []

const ListEnterprise = () => {
  return (
    <Base
      title={"List Enterprises"}
      id={"enterprises_list"}
      endpoint={"/enterprises/list"}
      description={
        "This endpoint lists the enterprises belongs to you. No parameters are required in the body."
      }
      codeSnippets={[
        {
          type: "response",
          code: response,
        },
      ]}
    >
      <ParameterGroup params={params} />
    </Base>
  )
}

export default ListEnterprise

const response = `{
    "message": "Success",
    "enterprises": [
        {
            "enterprise_id": "fffa1cb3-6a19-4078-a6c5-9f1c79acbba6",
            "name": "Enterprise B",
            "external_id": "7160144241",
            "stripe_customer_id": "cus_Je6HsyT4Db6wEc",
            "stripe_payment_method_id": "pm_1J0o5OJo8ARofgK0nh5IxWo2"
        },
    ]
}`
