import React from "react"
import Base, { ParameterGroup, createCodeFromParams } from "./_base"
import { addDays, format } from "date-fns"
// import { addDays, format } from "date-fns"

const params = [
  {
    name: "location_id",
    type: "uuid",
    description: "The uuid of the location",
    required: true,
    exampleValue: `"${process.env.GATSBY_COMMON_UUID}"`,
  },
  {
    name: "office_id",
    type: "uuid",
    required: true,
    description: "The uuid of the office to be reserved",
    example: "",
    exampleValue: `"4bb7163c-4eda-4a17-9f18-3f59cb7a23e3"`,
  },
  {
    name: "enterprise_id",
    type: "string",
    required: true,
    description: "The ID of the enterprise that the employee works in",
    exampleValue: "077cd53c-0178-484f-9daf-e1cd1d6869e0",
  },
  {
    name: "from",
    type: "ISO date string",
    required: true,
    description: "Reservation date in ISO format",
    example: "",
    exampleValue: `"${
      format(addDays(new Date(), 2), "yyyy-MM-dd") + "T09:00:00"
    }"`,
  },
  {
    name: "to",
    type: "ISO date string",
    required: true,
    description: "Reservation date in ISO format",
    example: "",
    exampleValue: `"${
      format(addDays(new Date(), 3), "yyyy-MM-dd") + "T17:00:00"
    }"`,
  },
  {
    name: "user",
    type: "object",
    description: "Details of the user",
    example: "",
    exampleValue: "",
    required: true,
    items: [
      {
        name: "name",
        type: "string",
        description: "Name of the user",
        required: true,
        defaultValue: "John Smith",
        exampleValue: "John Smith",
      },
      {
        name: "email",
        type: "string",
        description: "Email of the user",
        example: "",
        defaultValue: "",
        exampleValue: "john@smith.com",
        required: true,
      },
      {
        name: "phone",
        type: "date",
        description: "Phone number of the user",
        example: "7147147147",
        exampleValue: "7147147147",
        required: true,
        quotes: true,
      },
    ],
  },
  {
    name: "isHourly",
    type: "boolean",
    required: true,
    description: "If the reservation is daily or hourly",
    example: "",
    defaultValue: "false",
    exampleValue: "false",
  },
  {
    name: "isConfirmed",
    type: "boolean",
    required: true,
    description:
      "true for confirmed reservations, false for tentative reservations.",
    example: "",
    defaultValue: "false",
    exampleValue: "false",
  },
  {
    name: "number_of_hours",
    type: "integer",
    required: true,
    description: "Number of hours. Required only if isHourly it true.",
    example: "",
    defaultValue: "0",
    exampleValue: "0",
  },
  {
    name: "number_of_days",
    type: "integer",
    required: true,
    description: "Number of days. Required only if isHourly it false.",
    example: "",
    defaultValue: "0",
    exampleValue: "2",
  },
]

const ReserveOnDemandOffices = () => {
  return (
    <Base
      title={"Reserve On-demand Offices"}
      id={"reserve_on_demand_offices"}
      endpoint={"/reserve/on-demand-offices"}
      description={"This endpoint reserves and process payment for day pass"}
      codeSnippets={[
        {
          type: "body",
          code: createCodeFromParams(params),
        },
        {
          type: "response",
          code: response,
        },
      ]}
    >
      <ParameterGroup title={"Request body parameters"} params={params} />
    </Base>
  )
}

export default ReserveOnDemandOffices

const response = `{
    "message": "Success",
    "reservation": {
        "reservation_id": "06a75ad1-2cec-4532-acd5-ed451b2bd0cd",
        "location_id": "077cd53c-0178-484f-9daf-e1cd1d6869e0",
        "from": "${format(addDays(new Date(), 2), "yyyy-MM-dd") + "T09:00:00"}",
        "to": "${format(addDays(new Date(), 3), "yyyy-MM-dd") + "T17:00:00"}",
        "enterprise_id": "077cd53c-0178-484f-9daf-e1cd1d6869e0",
        "user": {
            "name": "John Smith",
            "email": "john@smith.com",
            "phone": "7147147147"
        },
        "isHourly": false,
        "price_per_day": 150,
        "number_of_days": 1,
        "tax": 19.5,
        "total": 169.5,
        "isConfirmed": false
    }
}`
