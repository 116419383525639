import React from "react"
import Base, { ParameterGroup, createCodeFromParams } from "./_base"
import { v4 as uuid } from "uuid"

const params = [
  {
    name: "space_operator_id",
    type: "uuid",
    description:
      "The uuid of the space operator. When specified, the results will include offices only from the locations that belongs to the specified Space Operator. When null, this endpoint will return all the locations that offer desks.",
    example: "",
    required: false,
    defaultValue: "NULL",
    exampleValue: process.env.GATSBY_COMMON_UUID,
    quotes: true,
  },
]

const SearchDayPasses = () => {
  return (
    <Base
      title={"Search Desks"}
      id={"search_desks"}
      endpoint={"/search/desks"}
      description={
        "This endpoint retrieves a list of Location objects that offer desks for the specified search criteria."
      }
      codeSnippets={[
        {
          type: "body",
          code: createCodeFromParams(params),
        },
        {
          type: "response",
          code: response,
        },
      ]}
    >
      <ParameterGroup title={"Request body parameters"} params={params} />
    </Base>
  )
}

export default SearchDayPasses

const response = `{
  "message": "Success",
  "locations": [
    {
      "location_id": "dbd69df5-cc6b-48ba-b879-f648e6cf1dfb",
      "name": "Worksimply Test Location",
      "title": "Pay for what you need, when you need it. ",
      "description": "Pay for what you need, when you need it. Our bright and inspiring private offices come fully equipped so you can focus on your business.",
      "cover_image": "https://images.worksimply.ca/local/space-providers/fa89d6e6-c7ab-4ebf-81e9-050a6df7825c.jpg",
      "location": {
        "lat": 43.51245979999999,
        "lng": -79.6782796,
        "address": "Winston Park Dr, Oakville, ON L6H, Canada",
        "heading": 0,
        "camera": {
            "lat": 43.51245979999999,
            "lng": -79.6782796
        },
        "short_address": "Winston Park Dr",
        "city": "Oakville",
        "place_id": "EilXaW5zdG9uIFBhcmsgRHIsIE9ha3ZpbGxlLCBPTiBMNkgsIENhbmFkYSIuKiwKFAoSCY3OZTMKQyuIEY1TMEnQGP4REhQKEgl5mXKPi1sriBHQGyPHKHsDBQ"
      },
      "price_per_day_pass": 25
    }
  ]
}`
