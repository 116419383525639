import React from "react"
import * as s from "./base.module.less"
import Code from "../../../components/Code"

const Base = ({
  title = "",
  id = "",
  type = "post",
  endpoint = "",
  description = "",
  children,
  codeSnippets = [],
}) => {
  return (
    <div className={s.new_section} id={id} name={id}>
      <div className={"row"}>
        <div className="col-lg-6">
          <h3>{title}</h3>
          <a href={`#${id}`} className={s.endpoint}>
            <span type={type}>{type}</span>
            <span>{endpoint}</span>
          </a>
          {description && <p>{description}</p>}
          <br />
          {children}
        </div>
        <div className="col-lg-6">
          {codeSnippets.map(({ type, code }, idx) => (
            <CustomCode type={type} codeString={code} key={idx} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default Base

const CustomCode = ({ type = "", codeString = "" }) => {
  return (
    <>
      <Code title={titles[type]}>{codeString}</Code>
    </>
  )
}

export const ParameterGroup = ({ params, title = null }) => {
  return (
    <>
      {title && <h4>{title}</h4>}
      <ul className={s.params_list}>
        {params.map((param, idx) => {
          return <ParamLi param={param} key={idx} />
        })}
      </ul>
    </>
  )
}

const ParamLi = ({
  param: {
    name = "",
    type = "",
    description = "",
    example = "",
    defaultValue,
    required = false,
    items = [],
  },
}) => {
  return (
    <li>
      <p className={s.param_name}>
        {name} <span>{type}</span>
        <span>{required ? "*" : ""}</span>
      </p>
      <p className={s.param_description}>{description}</p>
      {typeof defaultValue !== "undefined" && (
        <p className={s.param_default_value}>
          <span>Default:</span> {defaultValue}
        </p>
      )}
      {example && (
        <p className={s.param_example}>
          <span>Example:</span> {example}
        </p>
      )}
      {type === "object" && <Object items={items} />}
    </li>
  )
}

const Object = ({ items = [] }) => {
  return (
    <ul className={`${s.params_list} ${s.params_list_inner}`}>
      {items.map((param, idx) => {
        return <ParamLi param={param} key={idx} />
      })}
    </ul>
  )
}

const titles = {
  body: "Request body",
  response: "Response",
}

export const createCodeFromParams = (params = []) => {
  let variable = `{
${params
  .map((param, idx) => {
    let value = ""
    if (param.type === "object") {
      value += `{`
      value += param.items.map((item, idx2) => {
        return `\n      ${item.name}: ${getValue(item)}`
      })
      value += `\n   }`
    } else {
      value = getValue(param)
    }
    return `${idx > 0 ? `\n` : ""}   ${param.name}: ${value}`
  })
  .join(",")}
}`
  return variable
}

const getValue = param => {
  return param.type === "string" || param.quotes
    ? `"${param.exampleValue}"`
    : param.exampleValue
}
