import React from "react"
import Base, { ParameterGroup, createCodeFromParams } from "./_base"

const params = [
  {
    name: "enterprise_id",
    type: "uuid",
    description: "The uuid of the Enterprise to be updated",
    required: true,
    exampleValue: `"3b9bfff7-b692-424f-8ab3-cc6627fb1c40"`,
  },
  {
    name: "data",
    type: "object",
    description:
      "The data to be updated. Only the values passed in will be updated.",
    example: "",
    exampleValue: "",
    required: true,
    items: [
      {
        name: "name",
        type: "string",
        description: "The name of the Enterprise",
        exampleValue: "Enterprise B",
      },
      {
        name: "stripe_customer_id",
        type: "string",
        description: "The name of the Enterprise",
        exampleValue: "cus_Je6HsyT4Db6wEc",
      },
      {
        name: "stripe_payment_method_id",
        type: "string",
        description: "The name of the Enterprise",
        exampleValue: "pm_1J0o5OJo8ARofgK0nh5IxWo2",
      },
    ],
  },
]

const UpdateEnterprise = () => {
  return (
    <Base
      title={"Update Enterprises"}
      id={"enterprises_update"}
      endpoint={"/enterprises/update"}
      description={
        "This endpoint updates the Enterprise for the provided ID. Only the name, stripe_customer_id and stripe_payment_method_id are editable. This endpoint will update only the provided values."
      }
      codeSnippets={[
        {
          type: "body",
          code: createCodeFromParams(params),
        },
        {
          type: "response",
          code: response,
        },
      ]}
    >
      <ParameterGroup title={"Request body parameters"} params={params} />
    </Base>
  )
}

export default UpdateEnterprise

const response = `{
    "message": "Success",
    "enterprise": {
        "name": "Enterprise B",
        "stripe_customer_id": "cus_Je6HsyT4Db6wEc",
        "stripe_payment_method_id": "pm_1J0o5OJo8ARofgK0nh5IxWo2",
        "external_id": "7160144241",
        "enterprise_id": "fffa1cb3-6a19-4078-a6c5-9f1c79acbba6"
    }
}`
