import React from "react"
import Base, { ParameterGroup, createCodeFromParams } from "./_base"
import { addDays, format } from "date-fns"

const params = [
  {
    name: "location_id",
    type: "uuid",
    description: "The uuid of the location",
    required: true,
    exampleValue: `"${process.env.GATSBY_COMMON_UUID}"`,
  },
  {
    name: "number_of_desks",
    type: "integer",
    required: true,
    description: "Number of desks to be reserved",
    example: "",
    defaultValue: "1",
    exampleValue: "2",
  },
  {
    name: "from",
    type: "ISO date string",
    required: true,
    description: "Reservation date in ISO format",
    example: "",
    exampleValue: `"${
      format(addDays(new Date(), 2), "yyyy-MM-dd") + "T09:00:00"
    }"`,
  },
  {
    name: "to",
    type: "ISO date string",
    required: true,
    description: "Reservation date in ISO format",
    example: "",
    exampleValue: `"${
      format(addDays(new Date(), 2), "yyyy-MM-dd") + "T17:00:00"
    }"`,
  },
  {
    name: "enterprise_id",
    type: "string",
    required: true,
    description: "The ID of the enterprise that the employee works in.",
    exampleValue: "077cd53c-0178-484f-9daf-e1cd1d6869e0",
  },
  {
    name: "user",
    type: "object",
    description: "Details of the user",
    example: "",
    exampleValue: "",
    required: true,
    items: [
      {
        name: "name",
        type: "string",
        description: "Name of the user",
        required: true,
        defaultValue: "John Smith",
        exampleValue: "John Smith",
      },
      {
        name: "email",
        type: "string",
        description: "Email of the user",
        example: "",
        defaultValue: "",
        exampleValue: "john@smith.com",
        required: true,
      },
      {
        name: "phone",
        type: "date",
        description: "Phone number of the user",
        example: "7147147147",
        exampleValue: "7147147147",
        required: true,
        quotes: true,
      },
    ],
  },
  {
    name: "isConfirmed",
    type: "boolean",
    required: true,
    description:
      "true for confirmed reservations, false for tentative reservations.",
    example: "",
    defaultValue: "false",
    exampleValue: "false",
  },
]

const ReserveDayPasses = () => {
  return (
    <Base
      title={"Reserve Desks"}
      id={"reserve_desks"}
      endpoint={"/reserve/desks"}
      description={
        "This endpoint reserves desks for a given location and processes payment"
      }
      codeSnippets={[
        {
          type: "body",
          code: createCodeFromParams(params),
        },
        {
          type: "response",
          code: response,
        },
      ]}
    >
      <ParameterGroup title={"Request body parameters"} params={params} />
    </Base>
  )
}

export default ReserveDayPasses

const response = `{
    "message": "Success",
    "reservation": {
        "reservation_id": "3b9bfff7-b692-424f-8ab3-cc6627fb1c40",
        "location_id": "${process.env.GATSBY_COMMON_UUID}",
        "enterprise_id": "077cd53c-0178-484f-9daf-e1cd1d6869e0",
        "number_of_desks": 2,
        "from": "${format(addDays(new Date(), 2), "yyyy-MM-dd") + "T09:00:00"}",
        "to": "${format(addDays(new Date(), 2), "yyyy-MM-dd") + "T17:00:00"}",
        "user": {
            "name": "John Smith",
            "email": "john@smith.com",
            "phone": "7147147147"
        },
        "price_per_desk": 25,
        "tax": 6.5,
        "total": 56.5,
        "isConfirmed": false
    }
}`
